/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import nurseCheckingPatient from '../images/heroes/nurse-checking-patient.jpg'
import purpleCheckMark from '../images/icons/purple-check.svg'
import womanMobilePhone from '../images/people/woman-on-mobile-phone.jpg'

import { StaticImage } from 'gatsby-plugin-image'
import CountdownTimer from '../components/Countdown'
import DynamicCTACard from '../components/DynamicCTACard'
import SeoCtaBar from '../components/SeoCtaBar'

import {
  Columns,
  Column,
  Hero,
  Link,
  LinkButton,
  SplitContent,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../components/Footer'
import header from '../components/Header'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-hmo']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: 'Aetna Medicare HMO Plans | 833-217-0309 | Call Today',
          description:
            'Compare Aetna Medicare HMO plans and enroll in the one that fits your healthcare needs. Call Today 833-217-0309.',
        },
        path: '/hmo',
        promoCode: '151146',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicare',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="hero-content">
          <div className="wrapper">
            <Hero
              variant="full"
              className="leshen-hero"
              image={
                <img
                  src={nurseCheckingPatient}
                  alt="elderly woman gets her pulse checked by a nurse with a stethoscope"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Aetna<sup>®</sup> Medicare HMO Plans{' '}
                  </Typography>

                  <DynamicCTACard />
                </>
              }
            />
          </div>
        </div>
        <SeoCtaBar />
        <CountdownTimer />
        <VariableContent>
          <Columns className="icon-two-column">
            <Column className="icon-column">
              <StaticImage
                src="../images/icons/purple-heart.svg"
                alt="location icon"
                layout="fixed"
                width={100}
                height={100}
                className="column-icon"
              />
            </Column>
            <Column>
              <Typography variant="h2">What is a Medicare HMO plan?</Typography>
              <Typography variant="body">
                A Health Maintenance Organization (HMO) plan requires you to
                receive medical treatment from specific hospitals and doctors
                within a certain network. HMO plans are required to provide you
                with Original Medicare benefits, just like any other Advantage
                plan. HMO plans are limited when it comes to out-of-network
                treatment, though that care is still available. In cases of
                emergency, you can visit an out-of-network doctor or hospital to
                take care of your health needs.
              </Typography>
              <Typography variant="body">
                There are also Health Maintenance Organization Point-of-Service
                (HMO-POS) plans that give you more flexibility in choosing a
                doctor or hospital, as they allow out of network options in
                certain circumstances. Although rates usually go up for these
                HMO-POS plans, the convenience of having a more extensive
                network may be worth it for your situation. If you’re not sure
                how large of a health network you need, call one of our Licensed
                agents and they’ll walk you through the process.
              </Typography>
              <Typography variant="h4">
                Medicare Advantage: PPO, HMO, and DSNP plans
              </Typography>
              <Typography variant="body">
                An Aetna Medicare HMO plan may be exactly what you’re looking
                for. If that’s the case, call to see if you’re eligible to{' '}
                <a href="/apply">enroll now!</a> There is value in understanding
                all of your options, though, and Medicare Advantage has plenty
                of choices. Some HMO, DSNP, and PPO plans are available with a
                $0 monthly premium, and each plan has its own specific benefits.
              </Typography>
              <div className="comparison-table">
                <table className="table-main align-text-left">
                  <thead>
                    <td></td>
                    <td>Aetna Medicare PPO</td>
                    <td>Aetna Medicare HMO</td>
                    <td>Aetna Medicare DNSP</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Use of provider network required</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <sup>*</sup>
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Primary care physician required</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                      </td>
                      <td>No</td>
                    </tr>
                    <tr>
                      <td>Monthly premiums</td>
                      <td>Varies by plan</td>
                      <td>Varies by plan</td>
                      <td>Varies by plan</td>
                    </tr>
                    <tr>
                      <td>Prescription drug coverage included</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <sup>**</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>Dental, vision, and hearing coverage</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        in most plans
                      </td>
                    </tr>
                    <tr>
                      <td>Fitness benefit</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                        <br />
                        through Silversneakers<sup>®</sup>
                      </td>
                    </tr>
                    <tr>
                      <td>Medicaid enrollment required</td>
                      <td>No</td>
                      <td>No</td>
                      <td>
                        <img
                          src={purpleCheckMark}
                          alt="purple checkmark icon"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Typography variant="body" className="table-after">
                  *Use of provider network is required by an HMO plan unless
                  there’s an emergency.
                  <br />
                  **DSNP is a special type of a Medicare Advantage Prescription
                  Drug plan.
                </Typography>
              </div>
            </Column>
          </Columns>
        </VariableContent>

        <SplitContent
          backgroundColor="lightGray"
          alignImageToBottom={false}
          image={
            <img
              src={womanMobilePhone}
              alt="an elderly woman talks on mobile phone while holding glasses and smiling"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                Enroll in an Aetna Medicare Advantage HMO plan
              </Typography>
              <Typography variant="body">
                Is the Medicare HMO plan right for you? Then get started by
                tapping or clicking the button below. If you need help during
                the enrollment process, contact a friendly Licensed agent at{' '}
                <Link to={`tel:${rotatedNumber}`}>{rotatedNumber}</Link>.
              </Typography>
              <Typography variant="body">
                Need more info before you apply? No problem.{' '}
                <strong color="primary">
                  Request a FREE Medicare HMO quote
                </strong>{' '}
                by entering your zip code below.
              </Typography>
              <div className="card bg-light">
                <Typography variant="h4">
                  Request your complimentary Medicare insurance quote{' '}
                </Typography>
                <LinkButton
                  variant="feature"
                  to="/form"
                  color="primary"
                  className="margin-x-auto"
                >
                  Get Quote
                </LinkButton>
              </div>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
